import * as React from 'react'

import { t, tn } from '@owl-nest/localize'
import { text } from '@owl-nest/utils'
import * as api from '@owl-nest/api-client/latest'
import * as models from '@owl-nest/models'
import * as hooks from '@owl-nest/hooks'

import * as UFE from '../UFE'

import { ClassicProjectCard, NextProjectCard, type ProjectCardProps } from '../ProjectCard'
import { MembershipProjectCard, type MembershipCardProjectProps } from '../MembershipProjectCard'

type CardTypeSelectorProps = {
  avatar?: MembershipCardProjectProps['avatar']
  cardType: 'classic' | 'next'
  isStaff?: boolean // we need this if you don't pass projectTranslation
  mainImage?: MembershipCardProjectProps['mainImage']
  membershipTranslation?: MembershipCardProjectProps['translations']
  projectTranslation?: ProjectCardProps['translation']
  target?: '_blank'
} & Omit<ProjectCardProps, 'translation'>

export function CardTypeSelector({
  avatar,
  cardType,
  isStaff,
  lang = UFE.USER_LOCALE,
  mainImage,
  membershipTranslation,
  project,
  projectTranslation,
  target,
  ...props
}: CardTypeSelectorProps) {
  if (project.type === api.ProjectType.Membership) {
    return (
      <MembershipProjectCard
        avatar={avatar}
        lang={lang}
        mainImage={mainImage}
        project={project}
        size={props.size}
        target={target}
        translations={membershipTranslation ?? getDefaultTranslationMembership()}
      />
    )
  }

  if (cardType === 'classic') {
    return (
      <ClassicProjectCard
        {...props}
        project={project}
        target={target}
        translation={projectTranslation ?? getDefaultTranslationProject()}
      />
    )
  }

  if (cardType === 'next') {
    return (
      <NextProjectCard
        {...props}
        lang={lang}
        project={project}
        translation={projectTranslation ?? getDefaultTranslationProject()}
      />
    )
  }

  return <></>

  function getDefaultTranslationProject(): ProjectCardProps['translation'] {
    const isProjectFinished = models.project.isFinished(project)
    const textFinishProject =
      isProjectFinished && project.date_end ? models.project.getFinishedLabel(project.date_end) : t('Finished')
    const shortCountdown =
      project.date_end &&
      hooks.useDateCountdown(new Date(project.date_end), { automaticRefresh: true, shortLabels: true })

    return {
      addToMyList: t('Add to my list'),
      listed: t('Listed'),
      backerCount: tn(
        '%s contribution',
        '%s contributions',
        project.orders_count || 0,
        text.numberToLocaleString(project.orders_count || project.supporters_count),
      ),
      comingSoon: models.project.comingSoonLabel(project, Boolean(isStaff)),
      extraTime: t('Extension'),
      finished: textFinishProject,
      highlights: t('Highlights'),
      progressInfo: t('%(percent)s reached', {
        percent: `${text.numberToLocaleString(models.project.progress(project))}%`,
      }),
      shortCountdown: `${shortCountdown ? shortCountdown.label : ''}`,
      status: models.project.getStatus(project) ?? (shortCountdown ? shortCountdown.label : undefined),
    }
  }

  function getDefaultTranslationMembership() {
    return {
      members: tn(
        '%s member',
        '%s members',
        project.subscriptions_count || 0,
        text.numberToLocaleString(project.subscriptions_count ?? 0),
      ),
      subscribe: t('Subscribe'),
    }
  }
}
